.contentWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
}
.pipelinePage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    padding: 1.75rem 0 5rem 0;
    .fdaNote{
        text-align: center;
        font-weight: 700;
        margin: 0;
    }
    .firstNote{
        margin: .5rem 0;
    }
    .productsSummary{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 90vw;
        // margin: 0 4rem;
        // align-items: center;
        position: relative;
        .productWrapper{
            display: flex;
            width: 200px;
            min-width: 130px;
            max-width: 500px;
            flex-direction: column;
            // justify-content: space-between;
            align-items: center;
            position: relative;
            margin: .5rem;
            padding: 1rem;
            // background-color: #006eff;
            background-color: #0053c0;
            border-radius: 25px;
            .productText{
                padding:  0 1rem;
                flex-grow: 1;
                h2{
                    // color: #FC01C2;
                    // color: #a80081;
                    color: white;
                    // text-shadow: 0px 0px 3px white;
                }

            }
            .numberLabel{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                flex-grow: 0;
                flex-shrink: 0;
                // background-color: white;
                // color: #006eff;
                background-color: #006eff;
                // background-color: #0053c0;
                color: white;
                border-radius: 100%;
                font-weight: 900;
                font-size: 1.5rem;
                line-height: 1.5rem;
                aspect-ratio: 1/1;
            }
            p{
                text-align: center;
                color: white;
            }
        }
    }
    .lumbarWrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        img{
            width: 100%;
        }
    }
    h2{
        text-align: center;
        color: #a80081;
        // font-size: 1rem;
    }
    p{
        width: 100%;
    }
    ul{
        padding-left: 20px;
        li{
            margin: 1rem 0;
        }
    }
    .productHeader{
        font-size: 2rem;
        color: #0053c0;
    }
    .carouselBorder{
        width: 100%;
        padding: .5rem 1rem;
        border: 2px solid #0053c0;
    }
}
.pipelineHeader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30rem;
    width: 100vw;
    background-image: linear-gradient(rgba(255, 255, 255, 0.0) 0%,rgb(0,0,0, 0.7) 95%), url(../../../public/images/hexagonsLowOpacityHighRes.png), url(../../../public/images/xerosynSEM2.png);
    background-size: cover;
    background-color: #1878ED;
    // background-position: center, bottom left, bottom right;
    background-position: 50% 50%, 0% 100%, 0% 100%;
    .textContent{
        width: 90%;
        color: white;
        h1{
            text-align: center;
            margin: 0 0 2rem 0;
            font-size: 4rem;
        }
    }
}
@media (max-width: 1600px) {
    .pipelinePage{
        width: 50%;
    }
}
@media (max-width: 600px) {
    
        .pipelinePage{
            width: 70%;
            .productsSummary{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                width: 100vw;
                // align-items: center;
                position: relative;
                .productWrapper{
                    display: flex;
                    flex-direction: row;
                    width: 70%;
                }
            }
        }
        .pipelineHeader{
            height: 16rem;
            .textContent{
                h1{
                    font-size: 2rem;
                }
            }
        }
    
}