.carouselWrapper{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px;
    button{
        z-index: 5;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: .25rem;
        text-align: center;
        height: 50px;
        width: 50px;
        font-size: 2.5rem;
        color: white;
        background-color: #a80081;
        border: none;
        border-radius: 10px;
        // background: transparent;
        // border: 2px solid #a80081;
        // color: #a80081;
    }
    .leftButton{
        top: 50%;
        left: -5%;
    }
    .rightButton{
        top: 50%;
        right: -5%;
    }
    .carouselItemsWrapper{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        overflow-x: hidden;
        overflow-y: hidden;
        // overflow-y: visible;
        .carouselItem{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            cursor: pointer;
            // border: 2px solid red;
            h5{
                text-align: center;
                color: #0053c0;
            }
            img{
                // height: auto;
                // width: 500px;
                height: 100%;
                width: auto;
            }
        }
        .carouselItem.active{
            width: 50%;
            // border: 2px solid lime;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all .5s;
            z-index: 3;
        }
        .carouselItem.hiddenItem{
            width: 50%;
            // border: 2px solid lime;
            position: absolute;
            top: 50%;
            left: 50%;
            scale: .25;
            opacity: .25;
            transform: translate(-200%, -50%);
            transition: all .5s;
            z-index: 1;
        }
        .carouselItem.nextItem{
            width: 50%;
            // border: 2px solid red;
            position: absolute;
            top: 50%;
            left: 50%;
            scale: .5;
            opacity: .5;
            transform: translate(50%, -50%);
            transition: all .5s;
            z-index: 2;
        }
        .carouselItem.prevItem{
            width: 50%;
            // border: 2px solid red;
            position: absolute;
            top: 50%;
            left: 50%;
            scale: .5;
            opacity: .5;
            transform: translate(-250%, -50%);
            transition: all .5s;
            z-index: 2;
        }
    }
}

.productHeader{
    text-align: center;
    margin: 2rem 0;
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.currentCarouselItemDescription{
    margin-top: 0;
}
.currentCarouselItemFDANote{
    font-weight: 700;
    text-align: center;
}
@media (max-width: 2680px) {
    .carouselWrapper{
        height: 300px;
        .carouselItemsWrapper{
            .carouselItem{
                img{
                    width: 400px;
                }
            }
        }
    }
}
@media (max-width: 1280px) {
    .carouselWrapper{
        height: 300px;
        .carouselItemsWrapper{
            .carouselItem{
                img{
                    width: 400px;
                }
            }
        }
    }
   
    

}
@media (max-width: 900px) {
    .carouselWrapper{
        height: 300px;
        .carouselItemsWrapper{
            .carouselItem{
                img{
                    width: 360px;
                }
            }
        }
    } 
}
@media (max-width: 820px) {
    .carouselWrapper{
        height: 250px;
        .carouselItemsWrapper{
            .carouselItem{
                img{
                    width: 300px;
                }
            }
        }
    } 
}
@media (max-width: 700px) {
    .carouselWrapper{
        height: 200px;
        .carouselItemsWrapper{
            .carouselItem{
                img{
                    width: 250px;
                }
            }
        }
    } 
}
@media (max-width: 578px) {
    .carouselWrapper{
        height: 180px;
        .leftButton{
            top: 50%;
            left: -10%;
        }
        .rightButton{
            top: 50%;
            right: -10%;
        }
        .carouselItemsWrapper{
            
            .carouselItem{
                justify-content: flex-end;
                width: 85%;
                img{
                    width: 225px;
                    height: auto;
                }
            }
        }
    } 
}

/* ----------------------------------------------
 * Generated by Animista on 2023-3-23 12:38:3
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  