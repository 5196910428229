.technologyHeader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30rem;
    min-width: 100vw;
    background-image: linear-gradient(rgba(255, 255, 255, 0.0) 0%,rgb(0,0,0, 0.7) 95%), url(../../../public/images/hexagonsLowOpacityHighRes.png), url(../../../public/images/xerosynSEM2.png);
    background-size: cover;
    background-color: #1878ED;
    // background-position: center, bottom left, center right;
    background-position: 50% 50%, 0% 100%, 100% 50%;
    .textContent{
        width: 90%;
        color: white;
        h1{
            text-align: center;
            margin: 0 0 2rem 0;
            font-size: 4rem;
        }
    }

}
.technologyPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    padding: 1.75rem 0 5rem 0;
    h2{
        text-align: center;
        color: #a80081;
    }
    .pinkSubtitle{
        text-align: center;
        font-size: 1.1rem;
        color: #a80081;
    }
    .pinkText{
        color: #a80081;

    }
    h3{
        color: #0053c0;
    }
    .listWrapper{
        text-align: center;
        h3{
            text-align: left;
        }
        ul{
            margin: 0;
            display: inline-block;
            text-align: left;
        }
    }
    ol li span{
        font-weight: 700;
        color: #0053c0;
    }
}
.sustainedDeliveryWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    .sustainedDeliveryText{
        // margin-right: 2rem;
        .listWrapper{
            ul{
                padding-left: 20px;
                li{
                    margin: 1rem 0;
                }
            }
        }
    }
    .deliveryDiagramWrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        width: 60%;
        height: 100%;
        // max-width: 500px;
        img{
            width: 100%;
        }
        p{
            text-align: center;
            font-weight: 1000;
            font-size: 1rem;
        }
    }
}
.bioactivityWrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // padding: 3rem 0;
    .bioactivityText{
        .listWrapper{
            p{
                text-align: left;
                margin-bottom: 0;
            }
            ul{
                padding-left: 20px;
                li{
                    margin: 1rem 0;
                }
            }
        }
    }
    .bioactivityDiagramWrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        width: 60%;
        height: 100%;
        // max-width: 500px;
        img{
            width: 100%;
            padding: 2rem 0;
        }
        p{
            margin-top: 0;
            text-align: center;
            font-weight: 1000;
            font-size: 1rem;
        }
    }
}
.moaWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    .moaText{
        ol{
            padding-left: 20px;
            li{
                margin: 1rem 0;
            }
        }
    }
    .moaDiagramWrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        img{
            width: 100%;
        }
        p{
            text-align: center;
            font-weight: 1000;
            font-size: 1rem;
        }
    }
}

@media (max-width: 1600px) {
    .technologyPage{
        width: 60%;
    }
}

@media (max-width: 1200px) {
    .sustainedDeliveryWrapper{
        flex-direction: column;
        align-items: flex-start;
        .deliveryDiagramWrapper{
            width: 80%;
            align-self: center;
        }
    }
    .bioactivityWrapper{
        flex-direction: column;
        align-items: flex-start;
        .bioactivityDiagramWrapper{
            align-self: center;
            width: 80%;
        }
    }

}
@media (max-width: 800px) {
    .sustainedDeliveryWrapper{
        .deliveryDiagramWrapper{
            width: 100%;
        }
    }
    .bioactivityWrapper{
        .bioactivityDiagramWrapper{
            width: 100%;
        }
    }
}
@media (max-width: 600px) {
    .technologyPage{
        width: 70%;
    }
    .technologyHeader{
        height: 16rem;
        .textContent{
            h1{
                font-size: 2rem;
            }
        }
    }
    .bioactivityWrapper{
        .bioactivityDiagramWrapper{
            width: 100%;
            img{
                width: 100%;
                max-height: 400px;
            }
        }
    }
}
