.aboutHeader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30rem;
    width: 100vw;
    background-image: linear-gradient(rgba(255, 255, 255, 0.0) 0%,rgb(0,0,0, 0.7) 95%), url(../../../public/images/hexagonsLowOpacityHighRes.png), url(../../../public/images/xerosynSEM2.png);
    background-size: cover;
    background-color: #1878ED;
    background-position: 50% 50%, 0% 100%, 0% 100%;
    // background-position: center, bottom left, bottom right;
    .textContent{
        width: 90%;
        color: white;
        h1{
            text-align: center;
            margin: 0 0 2rem 0;
            font-size: 4rem;
        }
    }

}
.aboutPage{
    width: 40%;
    padding: 1.75rem 0 5rem 0;
    .blueText{
        text-align: center;
        font-weight: bold;
        color: #0053c0;
    }
    .pinkText{
        text-align: center;
        color: #a80081;
    }
    ul{
        padding-left: 20px;
        li{
            margin: 1rem 0;
        }
    }
}

@media (max-width: 1600px) {
    .aboutPage{
        width: 50%;
    }
}
@media (max-width: 600px) {
    .aboutPage{
        width: 70%;
    }
    
    .aboutHeader{
        height: 16rem;
        .textContent{
            h1{
                font-size: 2rem;
            }
        }
    }

}