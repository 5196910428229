.teamHeader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30rem;
    width: 100vw;
    background-image: linear-gradient(rgba(255, 255, 255, 0.0) 0%,rgb(0,0,0, 0.7) 95%), url(../../../public/images/hexagonsLowOpacityHighRes.png), url(../../../public/images/xerosynSEM2.png);
    background-size: cover;
    background-color: #1878ED;
    background-position: 50% 50%, 0% 100%, 65% 0%;
    .textContent{
        width: 90%;
        color: white;
        h1{
            text-align: center;
            margin: 0 0 2rem 0;
            font-size: 4rem;
        }
    }

}

.contentWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
}
.teamPage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    padding: 2rem 0 2rem 0;
    h2{
        text-align: center;
        color: rgb(246,1,190);
    }
    .memberLink{
        text-decoration: none;
    }
}

// .memberPage{
//     width: 28%;
//     h2{
//         text-align: center;
//         color: rgb(246,1,190);
//     }
//     .memberLink{
//         text-decoration: none;
//     }
// }

@media (max-width: 1600px) {
    .teamPage{
        width: 50%;
    }
}
@media (max-width: 1040px) {
    .teamPage{
        width: 70%;
    }
}

@media (max-width: 600px) {
    .teamHeader{
        height: 16rem;
        .textContent{
            h1{
                font-size: 2rem;
            }
        }
    }

}
.membersWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
}
.personCardWrapper{
    display: flex;
    justify-content: center;
    filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.75));
    width: 100%;
}
.personDetails{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    overflow: hidden;
    height: 10rem;
    width: 100%;
    margin: 0rem 0rem 1rem 0rem;
    background-color: rgb(235,235,235);
    border: 2px solid rgb(207,207,207);
    border-radius: 20px;
    cursor: pointer;
    .personName{
        padding: 0 1rem;
        color: #a80081;
        margin-top: 1rem;
        font-size: 1.06rem;
        font-weight: 500;
        text-align: center;
    }
    .personTitle{
        padding: 0 1rem;
        color: #0053c0;
        text-align: center;
        font-weight: 500;
        font-size: .9rem;
    }
    .personDescription{
        color: rgb(14,31,88);
    }
    .selectPersonBtn{
        margin: 0 1rem 0rem 0rem;
        height: 32px;
        min-width: 32px;
        padding: .5rem;
        background-color: rgb(160, 212, 244);
        border: 1px solid black;
        border-radius: 100%;
        color: black;
        cursor: pointer;
    }
}
.selectedMemberWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 10;
    top: 2rem;
    left: 0;
    height: 100%;
    width: 100vw;
    backdrop-filter: blur(10px);
    .selectedMemberDetails{
        display: flex;
        overflow-y: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        max-height: 75vh;
        background-color: rgb(235,235,235);
        border: 2px solid #a80081;
        .selectedMemberContent{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            overflow-y: auto;
        }
        .selectedMemberName{
            padding: 0 1.5rem;
            text-align: center;
            color: #a80081;
        }
        .selectedMemberTitle{
            padding: 0 1.5rem;
            text-align: center;
            color: #0053c0;
        }
        .selectedMemberDescription{
            padding: 0 1.5rem 1rem;
        }
    }
    .closeSelectPersonBtn{
        position:sticky;
        top: 4px;
        cursor: pointer;
        align-self: flex-start;
        border-radius: 100%;
        min-height: 40px;
        min-width: 40px;
        margin: .2rem;
        color: #a80081;
        text-align: center;
        font-family: Arial, sans-serif;
        font-size: 1.5rem;
        font-weight: 900;
        vertical-align: middle;
        background-color: rgba(255, 255, 255, 0);
        border: 2px solid rgba(0, 0, 0, 0);
        transition: all .5s;
    }
    .closeSelectPersonBtn:hover{
        color: red;
    }
}
@media (max-width: 900px) {
    .selectedMemberWrapper{
        .selectedMemberDetails{
            width: 80%;
            
        }
    }
}
@media (max-width: 530px) {
    .personDetails{
        display: flex;
        flex-direction: column;
        justify-content:space-between;
        align-items: center;
        overflow: hidden;
        height: 27rem;
        width: 15rem;
        margin: 0rem 0rem 1rem 0rem;
        .personImg{
            width: 100%;
        }
        .selectPersonBtn{
            display: block;
            margin: 0 0 1rem 0;
        }
    }
    .selectedMemberWrapper{
        .selectedMemberDetails{
            .selectedMemberTitle{
                margin: 0;
            }
        }
    }
}
