.pressPage{
    width: 40%;
    padding: 1.75rem 0 5rem 0;
}
.pressHeader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30rem;
    width: 100vw;
    background-image: linear-gradient(rgba(255, 255, 255, 0.0) 0%,rgb(0,0,0, 0.7) 95%), url(../../../public/images/hexagonsLowOpacityHighRes.png), url(../../../public/images/xerosynSEM2.png);
    background-size: cover;
    background-color: #1878ED;
    // background-position: center, bottom left, top right;
    background-position: 50% 50%, 0% 100%, 65% 0%;
    .textContent{
        width: 90%;
        color: white;
        h1{
            text-align: center;
            margin: 0 0 2rem 0;
            font-size: 4rem;
        }
    }
}
.pressReleasesWrapper{
    display: flex;
    flex-direction: column-reverse;
}
.articleLink{
    margin: 0 0 1rem 0;
    text-decoration: none;
    .articleWrapper{
        border: 2px solid grey;
        padding: 1rem 2rem;
        h2{
            font-size: 1rem;
            color: #a80081;
        }
        p{
           color: #0053c0; 
        }


    }
}
.pressDetailsWrapper{
    margin: 0 0 2rem 0;
    .pressDetailsTitle{
        text-align: center;
        font-size: 1.5rem;
    }
    .pressDetailsInfo{
        font-weight: 400;
    }
}
.pressNavLinks{
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
}

@media (max-width: 1600px) {
    .pressPage{
        width: 50%;
    }
}

@media (max-width: 600px) {
    .pressPage{
        width: 70%;
    }
    
    .pressHeader{
        height: 16rem;
        .textContent{
            h1{
                font-size: 2rem;
            }
        }
    }

}