footer{
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat';
    // width: 100%;
    // height: 150px;
    border-top: 3px solid rgb(246,1,190);
    background-color: rgb(57, 57, 57);
    color: white;
    .footerIcon{
        // width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 2rem;
    }
    .footerWrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        // flex-wrap: wrap;
        padding: 1.5rem 3rem 0 3rem;
        // width: 100%;
    }
    .footerLinksWrapper{
        display: inline-flex;
        // width: 75%;
        // display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

    }
    .footerLink{
        font-size: .9rem;
        text-align: center;
        text-decoration: none;
        color: white;
        margin: 1.5rem 1rem;
    }
    .footerLink:hover{
        text-decoration: underline;
        color: rgb(0, 204, 255);
    }
    .contactInfo{
        font-size: .9rem;
        display: flex;
        align-items: flex-end;
        text-align: left;
        flex-direction: column;
        width: 40%;
        margin: 1.5rem 1rem;
        .mailAddress{
            text-align: right;
            span{
                font-weight: 700;
            }
        }
        p{
            margin: 0 0 1rem 0;
            white-space: nowrap;
        }
        p span{
            font-weight: 700;
        }
        p a{
            color: rgb(1, 205, 246);
        }
    }
    .copyrightWrapper{
        font-size: .9rem;
        // margin: 1.5rem 1rem;
    }
    .secondRowWrapper{
        font-size: .9rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
@media (max-width: 900px) {
    footer{

        .footerWrapper{
            flex-direction: column;
            align-items: center;
            padding: 1.5rem 1rem 0 1rem;
            .footerLinksWrapper{
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;        
            }
            .contactInfo{
                margin: 1rem 0 0 0;
                width: 100%;
                align-items: center;
                .mailAddress{
                    text-align: center;
                }
            }
        }
    }
}
