.navbarWrapper{
    // margin: 0 0 2rem 0;
    margin: 0;
    
}
.mobileLogo{
    display: none;
}

.desktopLink{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    flex-grow: 1;
    margin: 1rem .75rem;
    color: black;
}
.desktopMenuLink{
    text-decoration: none;
    color: black;
}
.mobileMenuLink{
    display: flex;
    text-decoration: none;
    color: black;
}
@media (max-width: 950px) {
    .desktopLink{
        margin: 1rem .25rem;
    }
}
@media (max-width: 899px) {
    .desktopLogo{
        display: none;
    }
    .mobileLogo{
        display: flex;
    }
}
@media (max-width: 600px) {
    .logoImgMobile{
        padding-top: .25rem;
    }
}