.root-layout{
    display: flex;
    flex-direction: column;
    // width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    .outletWrapper{
        display: flex;
        margin: 0 0 auto 0;
        flex-direction: column;
        justify-items: flex-end;
        align-items: center;
        width: 100%;
    }
    .backgroundWrapper{
        z-index: -1;
        overflow: hidden;
        position: fixed;
        opacity: .3;
        bottom: 0;
        height: 100vh;
        width: 100%;
        background-image: url(../../public/images/backgroundLeft.png), url(../../public/images/backgroundRight.png);
        background-size: cover;
        background-position: bottom left, bottom right;
    }
}
.pageWrapper{
    font-family: 'Montserrat';
}
.miniNavbar{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #1878ED;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7) 0%,rgba(255, 255, 255, 0.0) 95%);
    .miniNavLinksWrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 100%;
        .miniNavLink{
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 0 2rem;
            height: 4rem;
            border-color: white;
            border-style: solid;
            border-width: 0px 1px 0px 1px;
            color: white;
            text-decoration: none;
        }
        .miniNavLink:first-child{
            border-width: 0px 0px 0px 1px;
        }
        .miniNavLink:hover{
            color: white;
            text-decoration: underline;
        }
    }
}
@media (max-width: 530px) {
    .miniNavbar{
        justify-content: center;
        .miniNavLinksWrapper{
            flex-direction: column;
            .miniNavLink{
                width: 70%;
                border-width: 0px 1px 0px 1px;
            }
            .miniNavLink:first-child{
                border-width: 0px 1px 1px 1px;
            }
        }
    }
}
// @media (max-width: 1040px) {
//     .pageWrapper{
//         width: 80%;
//     }
// }