.homePage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .homePageHeader{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32rem;
        width: 100vw;
        // background-image: linear-gradient(rgba(255, 255, 255, 0.2) 0%,rgb(0,0,0, 0.7) 95%), url(../../../public/images/hexagonsFullOpacityHighRes.png), url(../../../public/images/XeroSynEMTestBlue.png);
        background-image: linear-gradient(rgba(255, 255, 255, 0.0) 0%,rgb(0,0,0, 0.7) 95%), url(../../../public/images/hexagonsLowOpacityHighRes.png), url(../../../public/images/skeleton2.png);
        // , url(../../../public/images/surgeon2.jpg);
        background-size: cover;
        background-color: #1878ED;
        background-position: center, bottom left, bottom right;
        .textContent{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 80%;
            color: white;
            h1{
                margin: 0 0 2rem 0;
                font-size: 2rem;
                text-align: center;
                // white-space: nowrap;
            }
            .secondLine{
                font-size: 1.5rem;
                font-style: italic;
                text-align: center;
                max-width: 950px;
                // white-space: nowrap;
            }
            .thirdLine{
                font-size: 1.25rem;
                font-style: italic;
                text-align: center;
                // white-space: nowrap;
            }
        }
        // .textContent{
        //     width: 80%;
        //     color: white;
        //     h1{
        //         margin: 0 0 2rem 0;
        //         font-size: 3rem;
        //         text-align: center;
        //         white-space: nowrap;
        //     }
        //     .secondLine{
        //         font-size: 1.25rem;
        //         font-style: italic;
        //         text-align: center;
        //         // white-space: nowrap;
        //     }
        //     .thirdLine{
        //         font-size: 1.25rem;
        //         font-style: italic;
        //         text-align: center;
        //         white-space: nowrap;
        //     }
        // }
    }
    .contentWrapper{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        height: 32rem;
        background-image: url(../../../public/images/syringe.png), url(../../../public/images/products/XeroDuo.png), linear-gradient(rgb(0,0,0, 0.58) 0%, rgba(255, 255, 255, 0.2) 95%);
        // background-image: url(../../../public/images/XeroSynEMTestBlue.png), linear-gradient(rgb(0,0,0, 0.58) 0%, rgba(255, 255, 255, 0.2) 95%);
        // background-image: url(../../../public/images/XeroSynDiagram.png), linear-gradient(rgb(0,0,0, 0.58) 0%, rgba(255, 255, 255, 0.2) 95%);
        background-color: #1664D9;
        // , url(../../../public/images/surgeon2.jpg);
        background-size:contain, cover;
        // background-size:contain;
        background-repeat: no-repeat;
        // background-color: #006eff;
        // background-position: 75%;
        background-position: 60% 50%, center right, bottom left;
        // margin: 1rem 0 0 0;
        // border: 2px solid #006eff;
        img{
            width: 50%;
        }
        .xeroGelDiagramWrapper{
            display: flex;
            justify-content: flex-start;
            padding: 0rem;
            width: 100%;
            
        }
        .xeroGelDescription{
            // background-color: #0053c0;
            // padding: 2rem 0;
            background-image: linear-gradient(rgba(0,0,0, 0.475)  0%, rgba(255,255,255, 0) 95%);
            // background-color: #006af482;
            background-color: #f400d882;
            color: white;
            // padding: 0 2rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 40%;
            .xeroSynSubtitle{
                text-align: center;
                font-style: normal;
                margin: .5rem 0;
                font-style: italic;
            }
            .xeroSynFDANote{
                text-align: center;
                font-style: normal;
                margin: 1rem 0 0 0;
                font-style: italic;
                font-weight: 700;
                font-size: 1rem;
            }
            .xeroGelFirstLine{
                margin: 0 0 1rem 0;
                font-size: 2rem;
            }
            h2{
                // text-shadow: rgb(0, 30, 255) 1px 0 10px;;
                word-wrap: normal;
                margin: .25rem 0;
                font-size: 1.5rem;
            }
            p{
                width: 60%;
                margin: .25rem 0;
                font-size: 1.25rem;
                // text-align: center;
                // white-space: nowrap;
            }
        }
    }
}

@media (max-width: 1840px) {
    .homePage{
        .contentWrapper{

            .xeroGelDescription{
                // width: 30%;
                p{
                    width: 80%;
                }
            }
        }
    }

}
@media (max-width: 1380px) {
    .homePage{
        // .homePageHeader{
        //     background-image: linear-gradient(rgba(255, 255, 255, 0.0) 0%,rgb(0,0,0, 0.7) 95%), url(../../../public/images/hexagonsLowOpacityHighRes.png), url(../../../public/images/skeleton2.png);
        // }
        .contentWrapper{
            .xeroGelDescription{
                width: 50%;
                p{
                    width: 80%;
                }
            }
        }
    }

}
@media (max-width: 1040px) {
    .homePage{
        .homePageHeader{
            .textContent{
                width: 85%;
            }
        }
        .contentWrapper{
            // background-position: 80%;

            .xeroGelDescription{
                width: 100%;
                p{
                    text-align: center;
                    width: 80%;
                }
            }
        }
        // .contentWrapper{
        //     width: 80%;
        // }
    }
}
@media (max-width: 829px) {
    .homePage{
        .homePageHeader{
            .textContent{
                h1{
                    font-size: 2rem;
                }
                .secondLine{
                    white-space: normal;
                }
                .thirdLine{
                    white-space: normal;
                }
            }
        }
        .contentWrapper{
            background-position: 50%;
            .xeroGelDescription{
                text-align: center;
                width: 100%;
                p{
                    width: 80%;
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .homePage{
        .homePageHeader{
            height: auto;
        padding: 2rem 0;
        min-height: 32rem;
            background-position: center, bottom left -27.5vw, bottom right;
            .textContent{
                h1{
                    white-space: normal;
                }
            }
        }
    }
}