.NotFoundWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 2rem;
    min-height: 375px;
    .header404{
        font-size: 5rem;
        color: #0053c0;
        margin: 0;
    }
    .message404{
        margin: 0;
        font-size: 1.2rem;
        text-align: center;
        color: #a80081;
    }
}