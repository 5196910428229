.contentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .mapWrapper {
    width: 100%;
    height: 300px;
    iframe {
      border: 0;
      width: 100%;
      height: 300px;
    }
  }
}
.contactPage {
  display: grid;
  grid-template-columns: calc(50% - 1rem) calc(50% - 1rem);
  column-gap: 2rem;
  width: 60%;
  padding: 1.75rem 0 5rem 0;
  .contactDetails {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // padding: 1rem;
    // border: 1px solid rgba(118,118,118,1);
    h2{
        color: #a80081;
    }
    .buildingImg{
        width: 100%;
    }
    .locationWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1rem;
      p {
        margin: 0;
        line-height: 24px;
        font-weight: 600;
      }
    }
  }
}

.contactHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30rem;
  width: 100vw;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgb(0, 0, 0, 0.7) 95%
    ),
    url(../../../public/images/hexagonsLowOpacityHighRes.png),
    url(../../../public/images/xerosynSEM2.png);
  background-size: cover;
  background-color: #1878ed;
  // background-position: center, bottom left, bottom right;
  background-position: 50% 50%, 0% 100%, 0% 100%;
  .textContent {
    width: 90%;
    color: white;
    h1 {
      text-align: center;
      margin: 0 0 2rem 0;
      font-size: 4rem;
    }
  }
}
.contactFormWrapper {
  display: flex;
  flex-direction: column;
  .formHeader{
    h2{
      color: #a80081;
    }
  }
  .successMessage {
    width: 100%;
    padding: 1rem 0;
    text-align: center;
    background-color: rgb(98, 250, 98);
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .form-control {
      margin: 1rem 0;
      width: 100%;
      text-align: center;
      label {
        display: block;
        width: 100%;
        text-align: left;
      }
    }
    input {
      font-family: "Montserrat";
      box-sizing: border-box;
      padding: 0.5rem;
      width: 100%;
    }
    textarea {
      font-family: "Montserrat";
      box-sizing: border-box;
      padding: 0.5rem;
      width: 100%;
      height: 10rem;
    }
    .submitBtn {
      max-width: 300px;
      button {
        border: none;
        border-radius: 12px;
        font-family: "Montserrat";
        color: white;
        background-color: #006eff;
        cursor: pointer;
        padding: 0.5rem;
        height: 3rem;
        width: 80%;
      }
      button:disabled {
        cursor: not-allowed;
        color: rgba(255, 255, 255, 0.5);
        background-color: rgba(0, 110, 255, 0.5);
      }
    }
  }
}

@media (max-width: 1200px) {
  .contactPage{
    width: 70%;
  }
}
@media (max-width: 900px) {
  .contactPage {
    grid-template-columns: 100%;
  }
}

@media (max-width: 600px) {
  .contactHeader {
    height: 16rem;
    .textContent {
      h1 {
        font-size: 2rem;
      }
    }
  }
}
